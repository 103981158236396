<template>
    <div>
        <h2 class="border-bottom pb-2 mb-3"><i class="fa fa-signal"></i> Bandwidth Reporting</h2>

        <div style="background-color: white; padding:3%;">
            <div class="report" v-if="report">
                <div class="row mb-3">
                    <div class="col-12 text-right">
                        <div class="form-inline">
                            <div class="form-group mr-1">
                                <input type="date" v-model="filter.from" class="form-control date" placeholder="From"/>
                            </div>
                            <div class="form-group mr-1">
                                <input type="date" v-model="filter.to" class="form-control date" placeholder="To"/>
                            </div>
                            <div class="form-group mr-1">
                                <select class="form-control" v-model="filter.provider">
                                    <option selected value="">--Any Provider--</option>
                                    <option v-for="item in dropdownProviders" :key="item.value" :value="item.value">{{ item.text }}</option>
                                </select>
                            </div>
                            <div class="form-group mr-1">
                                <select v-model="filter.client" class="form-control">
                                    <option value="" selected>-- Any Client --</option>
                                    <option v-for="client in dropdownClients" :key="client.value" :value="client.value">{{ client.text }}</option>
                                </select>
                            </div>
                            <div class="form-group mr-1">
                                <select v-model="filter.product" class="form-control">
                                    <option value="" selected>-- Any Product--</option>
                                    <option v-for="product in dropdownProducts" :key="product.value" :value="product.value">{{ product.text }}</option>
                                </select>
                            </div>
                            <button type="button" class="btn text-white mr-1" style="background-color: #383838" @click="load"><i class="fa fa-search" title="Search"></i> Search</button>
                        </div>
                    </div>
                </div>
                <div class="row mb-12" v-if="reportLoading">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body text-center">
                                <b-spinner style="width: 3rem; height: 3rem;" type="grow" label="Processing......" variant="primary"></b-spinner>
                                <h4>Processing..............</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="row stats mb-4">
                        <div v-for="(item,index) in usages.totals" v-bind:key="index" class="col-12 col-sm-3">
                            <div class="card mb-5">
                                <div class="card-body text-center">
                                    <h4>{{item.gb}}</h4>
                                    <h5>{{item.provider}}</h5>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex justify-content-center">
                        <div class="col-12">
                            <div class="chart">
                                <apexchart width="100%" height="100%" type="area" :options="chartOptions" :series="usages.usages"></apexchart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import axios from "axios";
    import swal from "sweetalert";
    import Vue from "vue";
    import VueApexCharts from 'vue-apexcharts'
    Vue.use(VueApexCharts)

    Vue.component('apexchart', VueApexCharts)
    export default {
        name: "covrage.Report",
        props:['user'],
        data: function () {
            return {
                action:'',
                campaign:null,
                item: {},
                chartdata:[],
                filter:{
                    from:'',
                    to:'',
                    provider: '',
                    client: '',
                    product: '',
                },
                dropdownProviders:[],
                dropdownClients: [],
                dropdownProducts: [],
                usages: {totals:[]},
                clients: [],
                states:[],
                cities:[],
                preloader: false,
                searching: false,
                reportLoading: false,
                chartOptions: {
                chart: {
                    id: 'vuechart-example',
                    type: 'area'
                },
                xaxis: {
                   type: 'datetime',
                    labels: {
                        datetimeUTC: false
                    }
                },
                stroke: {
                curve: 'smooth'
                },
                },
                series: [],
            }
        },
        created: function(){
            this.$root.preloader = false;
            this.filter.from = this.getDateFormatted(new Date());
            this.filter.to = this.getDateFormatted(new Date());
            this.GetProductList();
            this.GetClientsList();
            this.GetProviderList();
            window.addEventListener("keydown",this.windowListener);
        },
        destroyed: function() {
            window.removeEventListener("keydown",this.windowListener);
        },
        computed: {
            report() {
                let result = [];
                let data = {};
                for(let key in this.chartdata) {
                    for(let i in this.chartdata[key].data) {
                        if(!data[this.chartdata[key].name]) data[this.chartdata[key].name] = 0;
                        data[this.chartdata[key].name] = data[this.chartdata[key].name] + Number(this.chartdata[key].data[i].value)
                    }
                }

                for(let key in data) {
                    result.push({loaded: data[key].toFixed(2), provider: key})
                }
                return result
            }
        },
        methods:{
            windowListener: function(e){
                if(e.keyCode === 13 && this.report && !this.searching){
                    this.load();
                }
            },
            BandCal :function (bytes) {
                var i = Math.floor(Math.log(bytes) / Math.log(1024)),
                    sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

                return (bytes / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + sizes[i];
            },
            GetProviderList: function(){
                //Load the clients
                var request = {'apikey': this.$root.apikey};
                //this.$root.preloader = true;

                return axios.get(`${this.$root.serverUrl}/admin/dropdown/provider`, {params:request}).then(function(resp){
                    //Store the stats
                    this.dropdownProviders = (resp.data && resp.data.data)? resp.data.data : [];
                    //this.$root.preloader = false;
                }.bind(this));
            },
            GetProductList: function(){
                //Load the clients
                var request = {'apikey': this.$root.apikey};
                //this.$root.preloader = true;

                return axios.get(`${this.$root.serverUrl}/admin/dropdown/product/crawler`, {params:request}).then(function(resp){
                    //Store the stats
                    this.dropdownProducts = (resp.data && resp.data.data)? resp.data.data : [];
                    //this.$root.preloader = false;
                }.bind(this));
            },
            GetClientsList: function(){
                this.cities = [];
                var request = {'apikey': this.$root.apikey};

                return axios.get(`${this.$root.serverUrl}/admin/dropdown/client`, {params:request}).then(function(resp){
                    //Store the stats
                    this.dropdownClients = (resp.data)? resp.data.data : [];
                }.bind(this));
            },
            load: async function(){
                this.searching = true;
                this.reportLoading = true;
                //Load the campaign
                var request = {'apikey': this.$root.apikey,filters:{}};

                if(this.filter.from) request.filters.from = this.filter.from;
                if(this.filter.to) request.filters.to = this.filter.to;
                if(this.filter.provider) request.filters.provider = this.filter.provider;
                if(this.filter.client) request.filters.client_id = this.filter.client;
                if(this.filter.product) request.filters.product_id = this.filter.product;
                try{
                    var resp = await axios.get(`${this.$root.serverUrl}/admin/reports/proxies/bandwidth`, {params:request});
                    if(resp.data.error){
                        console.log(resp.data.error);
                        await swal('Error!!',"Error: " + resp.data.error,'error')
                    }
                    this.usages = resp.data.data;
                    // this.chartdata = [];
                    this.reportLoading = false;
                    this.searching = false;
                }catch(e){
                    console.log(e);
                    this.reportLoading = false;
                    this.searching = false;
                    await swal('Error!!',"Error: " + e,'error')
                }
            },
            getDateFormatted: function(d){
                var
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();

                if (month.length < 2)
                    month = '0' + month;
                if (day.length < 2)
                    day = '0' + day;
                return [year, month, day].join('-');
            },
        }
    }
</script>

<style scoped>
    .chart {
        width: 100%;
        height: 400px;
    }
</style>
